/* prettier-ignore */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap');
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-x: hidden;
}
body {
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  float: left;
  position: relative;
  margin: 0px;
  padding: 0px 0px 0 0px;
  font-family: "Arial Nova", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #454552;
  line-height: 120%;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
}

a {
  text-decoration: underline;
  color: #454552;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
a:hover {
  text-decoration: none;
}
a img {
  border: 0px;
}

img {
  vertical-align: middle;
}
img.resp {
  width: 100%;
}
input,
textarea,
select,
button {
  font-family: "Arial Nova", "Arial", sans-serif;
  margin: 0;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

form,
p,
table,
ul {
  margin: 0px;
  padding: 0px;
}
table {
  border-collapse: collapse;
}
table tr td {
  border-spacing: 0px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  padding: 0px;
  font-weight: 700;
}
strong {
  font-weight: 700;
}

textarea {
  resize: none;
}

textarea,
input[type="password"],
[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
[type="text"]:hover {
  border-color: hsl(0, 0%, 70%);
}

textarea:disabled {
  background: #f1f1f1;
}

textarea:hover {
  border-color: hsl(0, 0%, 70%);
}

input[type="text"]:disabled {
  background: #f1f1f1;
}

input[type="text"]:disabled:hover {
  border: 1px solid #ccd2d7;
}

input[type="number"]:hover {
  border-color: hsl(0, 0%, 70%);
}

input[type="number"]:disabled {
  background: #f1f1f1;
}

input[type="number"]:disabled:hover {
  border: 1px solid #ccd2d7;
}

textarea:disabled:hover {
  border: 1px solid #ccd2d7;
}

button[type="submit"]:disabled {
  background-color: #cccccc;
  border: 1px solid #c3c3c3c3;
  cursor: default;
  pointer-events: none;
}

button[type="button"]:disabled {
  background-color: #cccccc;
  border: 1px solid #c3c3c3c3;
  cursor: default;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

input,
textarea {
  box-sizing: border-box;
}

.f-left {
  float: left;
}
.f-right {
  float: right;
}
.center {
  text-align: center !important;
}
.align-right {
  text-align: right !important;
}
.align-left {
  text-align: left !important;
}
.align-middle {
  vertical-align: middle;
}
.underline {
  text-decoration: underline;
}
div.clear-x {
  clear: both;
  height: 1px;
  font-size: 1px;
  line-height: 0.5;
  display: block;
}
.clear.line {
  border: 0px;
  margin-bottom: 40px;
  margin-top: 30px;
  background: #e1e1e1;
}
.separator {
  float: left;
  width: 100%;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
}
.italic {
  font-style: italic;
}
.strong {
  font-weight: bold;
}
.no-padding {
  padding: 0 !important;
}
.nowrap {
  white-space: nowrap;
}
.no-overflow {
  overflow: visible !important;
}
.hidden {
  display: none !important;
}
.relative {
  position: relative;
}
.divider {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e8eb;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}
.w-66 {
  width: 66%;
}
.isDisable > a {
  cursor: not-allowed;
  pointer-events: none;
}

/* MARGINS */
.m-1 {
  margin: 1rem;
}
.mx-1 {
  margin: 0 1rem;
}
.my-1 {
  margin: 1rem 0;
}
.my-05 {
  margin: 0.5rem 0;
}
.m-left-0 {
  margin-left: 0 !important;
}
.m-left-1 {
  margin-left: 1rem;
}
.m-left-2 {
  margin-left: 2rem;
}
.m-left-5 {
  margin-left: 5rem;
}
.m-bot-0 {
  margin-bottom: 0px !important;
}
.m-bot-1 {
  margin-bottom: 1rem !important;
}
.m-bot-05 {
  margin-bottom: 0.5rem !important;
}
.m-top-1 {
  margin-top: 1rem !important;
}
.m-top-5 {
  margin-top: 0.5rem !important;
}

/* PADDINGS */
.p-left-0 {
  padding-left: 0 !important;
}
.p-bot-1 {
  padding-bottom: 1rem !important;
}
.p-top-1 {
  padding-top: 1rem !important;
}

.backgroud-blue {
  background-color: #cde0ee !important;
}
.border-black {
  border: 1px solid black;
}

svg {
  vertical-align: text-bottom;
  position: relative;
}
svg path {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

div {
  box-sizing: border-box;
}

.row {
  box-sizing: border-box;
  position: relative;
  text-align: left;
  margin: 0 -40px 0px -40px;
}
.row img {
  max-width: 100%;
}
.row .col-50 {
  width: 50%;
  box-sizing: border-box;
  float: left;
  padding: 0 40px;
}
.row .col-100 {
  width: 100%;
  box-sizing: border-box;
  float: none;
  padding: 0 40px;
}

.button {
  padding: 0px 15px;
  min-width: 145px;
  text-decoration: none;
  line-height: 36px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  background: #02385d;
  white-space: nowrap;
  color: #ffffff;
  border: 2px solid #02385d;
  font-size: 16px;
  font-weight: bold;
  -webkit-transition: all 0.2s ease !important;
  -moz-transition: all 0.2s ease !important;
  -o-transition: all 0.2s ease !important;
  -ms-transition: all 0.2s ease !important;
  transition: all 0.2s ease !important;
}
.button:hover {
  background: #0164a7;
  border-color: #0164a7;
  color: #ffffff;
}

.button.btn-light {
  background: #ffffff;
  border-color: #0164a7;
  color: #0164a7;
}
.button.btn-light:hover {
  background: #0164a7;
  border-color: #0164a7;
  color: #ffffff;
}
.pagination-button {
  min-width: auto;
  margin-bottom: 4px;
}
.pagination-button:disabled {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  cursor: default;
  pointer-events: none;
}
.table-filter-input {
  margin: 0px;
  padding: 0px 15px;
  border: 1px solid #ccd2d7;
  line-height: 20px;
  height: 22px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: #454552;
  font-size: 12px;
}
.table-filter-select {
  margin: 0px;
  padding: 0px 15px;
  border: 1px solid #ccd2d7;
  line-height: 20px;
  height: 22px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: #454552;
  font-size: 12px;
}
.inp-text {
  margin: 0px;
  padding: 0px 15px;
  border: 1px solid #ccd2d7;
  line-height: 40px;
  height: 42px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: #454552;
  font-size: 16px;
}
.inp-textarea {
  margin: 0px;
  padding: 0px 15px;
  border: 1px solid #ccd2d7;
  line-height: 40px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: #454552;
  font-size: 16px;
}
.inp-datepicker {
  margin: 0px;
  padding: 0px 15px;
  border: 1px solid #ccd2d7;
  line-height: 40px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: #454552;
  font-size: 16px;
}

.react-datepicker-wrapper {
  display: block !important;
}

label {
  font-size: 16px;
  color: #454552;
  box-sizing: border-box;
}

.styled-checkbox {
  appearance: none;
  background-color: #fff;
  display: grid;
  place-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 3px solid #ccd3d9;
  border-radius: 0.15em;
  color: #02385d;
}
.styled-checkbox::before {
  content: "";
  height: 12.5px;
  width: 12.5px;
  transform: scale(0);
  border-radius: 0.1em;
  background-color: #02385d;
}
.styled-checkbox:checked::before {
  transform: scale(1);
}
.styled-checkbox + label {
  margin-bottom: 0;
}

.max-w-1024 {
  max-width: 1024px;
  display: flex;
  flex-grow: 2;
}
.max-w-1524 {
  max-width: 1524px;
  display: flex;
  flex-grow: 2;
}
.max-w-2500 {
  max-width: 2500px;
  display: flex;
  flex-grow: 2;
}
.max-w-auto {
  width: auto;
  display: flex;
  flex-grow: 2;
}
.formSubTitle {
  margin-left: 1em;
}

/* Font color */
.color-b {
  color: #0164a7 !important;
}

/* Color stripe */
.color-stripe-blue {
  background-color: #279112;
  width: 100%;
  height: 0.5rem;
}

.color-stripe-red {
  background-color: #a63a49;
  width: 100%;
  height: 0.5rem;
}

.color-stripe-orange {
  background-color: #eca100;
  width: 100%;
  height: 0.5rem;
}

.color-stripe-none {
  background-color: #454552;
  width: 100%;
  height: 0.5rem;
}

/* Background color */
.bg-color-blue {
  background-color: #279112 !important;
}

.bg-color-red {
  background-color: #a63a49 !important;
}

.bg-color-orange {
  background-color: #eca100 !important;
}

.bg-color-none {
  background-color: #454552 !important;
}

/* FLEX BOX  */
.flex-container-row {
  display: flex !important;
  flex-direction: row !important;
}

/* FLEX BOX  */
.flex-container-column {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
}

/* Grid */
.grid-container-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex-grow: 1 !important;
}

.flex-2 {
  flex-grow: 2 !important;
}

#layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
}
#layout > div {
  display: flex;
  flex-direction: column;
  min-height: inherit;
}
#layout #aside {
  min-width: 230px, hidden;
  background: #0164a7;
  color: #cce0ed;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.33);
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
#layout #aside.collapsed {
  width: 64px;
  min-width: 64px;
}
#layout #aside a {
  color: #cce0ed;
}

#layout #content {
  flex-grow: 1;
}

#layout #aside .aside-footer {
  min-height: 66px;
  padding: 10px 0px;
  text-align: center;
  font-size: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#layout #aside .aside-footer > span {
  white-space: nowrap;
}

#layout #aside .aside-header {
  height: 66px;
  background: #02385d;
  padding: 10px;
  box-sizing: border-box;
}
#layout #aside .aside-header {
  display: flex;
  background: #000000;
  color: #ffffff;
  align-items: center;
  justify-content: left;
  padding: 0px 10px;
}
#layout #aside .aside-header .fa-caret-left {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
#layout #aside .aside-header i {
  vertical-align: middle;
  cursor: pointer;
}
#layout #aside .aside-header .fas {
  font-size: 15px;
}
#layout #aside .aside-header .fa-bars {
  font-size: 30px;
  margin-right: 5px;
}
#layout #aside .aside-header .fa-bars:hover {
  color: #ffffff;
}

#layout #aside.collapsed .aside-header .fa-caret-left {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

#layout #aside .aside-content {
  flex-grow: 1;
  display: flex;
}
#layout #aside .aside-content {
  padding: 20px 15px;
  text-align: left;
  position: relative;
}
#layout #aside .aside-content::after {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 15px;
  content: "";
  background: #0164a7;
}
#layout #aside .aside-content .main-menu {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
#layout #aside .aside-content .main-menu > li {
  padding: 11px 0px;
  margin: 0px;
  list-style: none;
}
#layout #aside .aside-content .main-menu > li > a {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  white-space: nowrap;
  text-decoration: none;
  line-height: 20px;
}
#layout #aside .aside-content .main-menu > li > a > i {
  vertical-align: middle;
  font-size: 20px;
  color: #cce0ed;
  width: 24px;
  text-align: center;
}
#layout #aside .aside-content .main-menu > li > a > i + span {
  margin-left: 14px;
}
#layout #aside .aside-content .main-menu > li > a:hover > i {
  color: #ffffff;
}
#layout #aside .aside-content .main-menu > li.active > a > i {
  color: #ffffff;
}
#layout #aside .aside-content .main-menu > li > ul {
  padding: 3px 0px 0px 55px;
  margin: 0px 0px -5px 0px;
  list-style-type: none;
}
#layout #aside .aside-content .main-menu > li > ul > li {
  line-height: 18px;
  padding: 6px 0px;
}
#layout #aside .aside-content .main-menu > li > ul > li a {
  font-size: 14px;
  color: #cce0ed;
  text-decoration: none;
  font-weight: 400;
}
#layout #aside .aside-content .main-menu > li > ul > li a:hover {
  color: #ffffff;
  text-decoration: underline;
}
#layout #aside .aside-content .main-menu > li > ul > li > ul > li {
  line-height: 18px;
  padding: 6px 0px;
  list-style-type: none;
  margin-left: 1rem;
}
#layout #aside .aside-content .main-menu > li > ul > li > ul > li a {
  font-size: 14px;
  color: #cce0ed;
  text-decoration: none;
  font-weight: 400;
}
#layout #aside .aside-content .main-menu > li > ul > li > ul > li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

#layout #content .layout-header {
  height: 66px;
  background: #02385d;
  padding: 10px;
  box-sizing: border-box;
}
#layout #content .layout-content {
  flex-grow: 1;
  display: flex;
}
#layout #content .layout-footer {
  min-height: 66px;
  padding: 10px 0px;
  text-align: center;
  font-size: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
#layout #content .layout-footer > span {
  white-space: nowrap;
}
#layout #content .layout-footer > span {
  margin: 0 auto;
}

#layout #content .layout-header {
  padding-left: 32px;
  padding-right: 5px;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.33);
}
#layout #content .layout-header a {
  color: #ffffff;
}
#layout #content .layout-header a:hover {
  color: #eca100;
}
#layout #content .layout-header #logo {
  color: #ffffff;
  font-size: 34px;
  font-weight: 700;
  flex-grow: 1;
}
#layout #content .layout-header #logo span {
  font-size: 10px;
  font-weight: 400px;
  padding-left: 9px;
}
#layout #content .layout-header #logo a {
  text-decoration: none;
}
#layout #content .layout-header .navigation > div {
  display: inline-block;
  padding: 0px 25px;
  position: relative;
  position: relative;
}
#layout #content .layout-header .navigation > div::before {
  background: #4f6b7e;
  width: 1px;
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -14px;
  height: 28px;
}
#layout #content .layout-header .navigation > div:first-child::before {
  display: none;
}
#layout #content .layout-header .navigation > div a > i {
  font-size: 30px;
  vertical-align: middle;
  margin-right: 10px;
}
#layout #content .layout-header .navigation > div a > span {
  padding-left: 10px;
}
#layout #content .layout-header .navigation > div a {
  font-size: 16px;
  text-decoration: none;
}

#layout #content .layout-header .navigation .account {
  display: inline-block;
  position: relative;
  z-index: 5000;
}
#layout #content .layout-header .navigation .account.active .account-menu {
  opacity: 1;
  visibility: visible;
}
#layout #content .layout-header .navigation .account.active > a {
  color: #eca100;
}
#layout #content .layout-header .navigation .account-menu {
  position: absolute;
  top: 23px;
  right: 8px;
  padding-top: 29px;
  padding-right: 17px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
#layout #content .layout-header .navigation .account-menu > div {
  background: #eca100;
  min-width: 250px;
  box-sizing: border-box;
  position: relative;
  padding: 21px 25px;
}
#layout #content .layout-header .navigation .account-menu > div::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 100%;
  height: 50px;
  width: 50px;
  border-top: 22px solid transparent;
  border-right: 22px solid transparent;
  border-bottom: 22px solid #eca100;
  border-left: 22px solid transparent;
}
#layout #content .layout-header .navigation .account-menu > div ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
#layout #content .layout-header .navigation .account-menu > div ul li {
  padding: 4px 0px;
  font-size: 14px;
  line-height: 18px;
  list-style: none;
}
#layout #content .layout-header .navigation .account-menu > div ul li a {
  text-decoration: none;
  font-size: 14px;
}
#layout #content .layout-header .navigation .account-menu > div ul li a:hover {
  text-decoration: none;
  color: #4f6b7e;
}

#layout #content .layout-header .wrap {
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#layout #content .layout-content #main nav.sub-page-navigation {
  margin: 15px 0 30px 0;
  border-bottom: 1px solid #e5e8eb;
  width: 100%;
}
#layout #content .layout-content #main nav.sub-page-navigation div {
  display: inline-block;
  margin: 20px 10px 14px;
  padding: 0 1rem;
  position: relative;
}
#layout
  #content
  .layout-content
  #main
  nav.sub-page-navigation
  div:first-of-type {
  padding: 0 1rem 0 0;
}
#layout #content .layout-content #main nav.sub-page-navigation div a {
  text-decoration: none;
  font-size: 16px;
  color: #b0b0b6;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  cursor: pointer;
}
#layout #content .layout-content #main nav.sub-page-navigation div a:hover {
  color: #02385d;
  text-decoration: none;
}
#layout #content .layout-content #main nav.sub-page-navigation div a.active {
  background-color: white;
  padding: 15px 30px;
  color: #0164a7;
  font-weight: bold;
  border-top: 1px solid #e5e8eb;
  border-left: 1px solid #e5e8eb;
  border-right: 1px solid #e5e8eb;
}

#layout #content .layout-footer {
  color: #454552;
  font-size: 12px;
}
#layout #content .layout-content {
  padding: 32px;
}

#layout #content .layout-content #main {
  display: flex;
  width: 100%;
}
#layout #content .layout-content #main > div:first-child {
  flex-grow: 1;
}
#layout #content .layout-content #main .sidebar {
  display: flex;
  width: 240px;
  padding-left: 30px;
}
#layout #content .layout-content #main h1 {
  color: #02385d;
  font-size: 22px;
  font-weight: bold;
  margin: 0px 0px 25px 0px;
  padding: 0px 0px 25px 0px;
  border-bottom: 1px solid #e5e8eb;
}
#layout #content .layout-content #main h2 {
  color: #0164a7;
  font-size: 18px;
  font-weight: bold;
  margin: 0px 0px 25px 0px;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #e5e8eb;
}
#layout #content .layout-content #main h2.message {
  color: #0164a7;
  font-size: 18px;
  font-weight: bold;
  margin: 0px 0px 25px 0px;
  padding: 0px 0px 20px 0px;
}

#layout #content .layout-content #main ul {
  margin: 0px 0px 1em 0px;
  padding: 0px;
  list-style-type: none;
}
#layout #content .layout-content #main ul li {
  padding-left: 11px;
  padding-bottom: 4px;
}
#layout #content .layout-content #main ul li::before {
  color: #02385d;
  float: left;
  margin-left: -11px;
  margin-top: 1px;
}

#layout #content .layout-content #main .signpost-wrap {
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
}
#layout #content .layout-content #main .signpost-wrap.search {
  padding-right: 3%;
  border-top: 1px solid #e5e8eb;
  margin-top: 10px;
  padding-top: 10px;
}
#layout #content .layout-content #main .signpost-wrap.menu {
  padding-right: 3%;
  border-top: 1px solid #e5e8eb;
  margin-top: 10px;
  padding-top: 10px;
}
#layout #content .layout-content #main .signpost-wrap > .signpost {
  flex: 6;
  padding-right: 2%;
}
#layout #content .layout-content #main .signpost-wrap > .signpost .item {
  flex: 0 1 16.6666666%;
  text-align: center;
}

#layout #content .layout-content #main .signpost-wrap > .signpost.single {
  flex: 1;
  position: relative;
  padding-right: 0;
  padding-left: 2%;
}
#layout
  #content
  .layout-content
  #main
  .signpost-wrap
  > .signpost.single::before {
  content: "";
  width: 1px;
  height: 54px;
  background: #e5e8eb;
  position: absolute;
  left: 0px;
  top: 26px;
}
#layout #content .layout-content #main .signpost-wrap > .signpost.single .item {
  flex: 0 1 100%;
  text-align: center;
}
#layout #content .layout-content #main .signpost-wrap.search > .signpost .item {
  flex: 0 1 14.2857%;
  text-align: center;
}

#layout #content .layout-content #main .signpost {
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
#layout #content .layout-content #main .signpost .item {
  flex: 0 1 12.5%;
  text-align: center;
  margin-bottom: 40px;
}
#layout #content .layout-content #main .signpost .item a {
  color: #454552;
  font-size: 16px;
  text-decoration: none;
}
#layout #content .layout-content #main .signpost .item a .icon {
  color: #cce0ed;
  display: inline-block;
  border: 2px solid #cce0ed;
  position: relative;
  font-size: 45px;
  border-radius: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
#layout #content .layout-content #main .signpost .item a .icon u {
  position: absolute;
  text-decoration: none;
  right: -7px;
  top: -7px;
  width: 35px;
  line-height: 35px;
  border-radius: 35px;
  background: #eca100;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
#layout #content .layout-content #main .signpost .item a .name {
  display: block;
  padding-top: 10px;
  line-height: 120%;
}
#layout #content .layout-content #main .signpost .item a:hover {
  color: #02385d;
}
#layout #content .layout-content #main .signpost .item a:hover .icon {
  color: #02385d;
  border-color: #02385d;
}

#layout #content .layout-content #main #login-box {
  background: #cce0ed;
  padding: 43px 50px;
  box-sizing: border-box;
  max-width: 420px;
  margin: 70px auto;
  text-align: center;
}
#layout #content .layout-content #main #login-box .form-row {
  margin-bottom: 10px;
}
#layout #content .layout-content #main #login-box .form-row .input-group {
  padding-bottom: 0;
}
#layout #content .layout-content #main #login-box .form-row .input-group label {
  flex: 0.3 1;
  line-height: 50px;
  float: left;
  margin-left: -40px;
  color: #02385d;
  font-size: 22px;
  margin-bottom: 0;
}
#layout #content .layout-content #main #login-box .form-row .input-group input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccd2d7;
  height: 50px;
  padding: 0px 15px;
  color: #454552;
  font-size: 16px;
}
#layout
  #content
  .layout-content
  #main
  #login-box
  .form-row
  .input-group
  input::-webkit-input-placeholder {
  /* Chrome */
  color: #454552;
  font-style: italic;
}
#layout
  #content
  .layout-content
  #main
  #login-box
  .form-row
  .input-group
  input:-ms-input-placeholder {
  /* IE 10+ */
  color: #454552;
  font-style: italic;
}
#layout
  #content
  .layout-content
  #main
  #login-box
  .form-row
  .input-group
  input::-moz-placeholder {
  /* Firefox 19+ */
  color: #454552;
  font-style: italic;
  opacity: 1;
}
#layout
  #content
  .layout-content
  #main
  #login-box
  .form-row
  .input-group
  input:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #454552;
  font-style: italic;
  opacity: 1;
}
#layout #content .layout-content #main #login-box .form-row-button {
  padding-bottom: 0;
}
#layout #content .layout-content #main #login-box .button {
  min-width: 145px;
  margin-top: 7px;
}

.form-background {
  background: #dfecf5;
}
#layout #content .layout-content #main .myForm {
  width: 100%;
  max-width: 2500px;
}
#layout #content .layout-content #main .form-row {
  display: flex;
  margin-bottom: 30px;
}
#layout #content .layout-content #main .form-row .form-column .form-row {
  display: flex;
  margin-bottom: 0px;
}
#layout #content .layout-content #main .form-row-button {
  text-align: center;
  padding-top: 0px;
  padding-bottom: 50px;
}
#layout #content .layout-content #main .form-row-button .button {
  margin: 5px;
}
#layout #content .layout-content #main .form-row-radio {
  display: flex;
  margin-bottom: 30px;
  margin-left: 32px;
}
#layout #content .layout-content #main .form-row-radio label {
  margin: 0 5px;
}
#layout #content .layout-content #main .form-row-radio input {
  margin-left: 30px;
  margin-right: 10px;
}

#layout #content .layout-content #main .inp-textarea {
  flex: 2 1;
}

#layout #content .layout-content #main .form-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 60px;
}
#layout #content .layout-content #main .form-column:first-child {
  padding-right: 60px;
}

#layout #content .layout-content #main .input-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 14px;
}
#layout #content .layout-content #main .input-group > label {
  flex: 1;
  width: 135px;
  max-width: 165px;
  margin-right: 0px;
  align-self: center;
  margin-bottom: 0;
}
#layout #content .layout-content #main .input-group > label + input {
  flex: 2;
  flex-grow: 1;
}
#layout #content .layout-content #main .input-group > label + textarea {
  flex: 2;
  flex-grow: 1;
}
#layout #content .layout-content #main .input-group > label + div {
  flex: 2;
  flex-grow: 1;
}
#layout #content .layout-content #main .input-group .input-icons {
  margin-left: 10px;
  font-size: 30px;
  color: #02385d;
}
#layout #content .layout-content #main .input-group .input-icons svg {
  margin: 3px;
}

#layout #content .layout-content #main .myContent {
  width: 100%;
}
#layout #content .layout-content #main .grid-row {
  display: flex;
  margin-bottom: 10px;
}
#layout #content .layout-content #main .grid-row.m-bot-0 {
  margin-bottom: 0px;
}
#layout #content .layout-content #main .grid-row.wrap {
  flex-wrap: wrap;
}
#layout #content .layout-content #main .grid-row .grid-column .gird-row {
  display: flex;
  margin-bottom: 0px;
}
#layout #content .layout-content #main .grid-row-button {
  text-align: center;
  padding-top: 0px;
  padding-bottom: 50px;
}
#layout #content .layout-content #main .grid-row-button .button {
  margin: 5px;
}
#layout #content .layout-content #main .grid-row-radio {
  display: flex;
  margin-bottom: 30px;
  margin-left: 32px;
}
#layout #content .layout-content #main .grid-row-radio label {
  margin: 0 5px;
}
#layout #content .layout-content #main .grid-row-radio input {
  margin-left: 30px;
  margin-right: 10px;
}
#layout #content .layout-content #main .grid-row-checkbox {
  display: flex;
  margin-bottom: 30px;
}

#layout #content .layout-content #main .grid-column {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-left: 60px;
}
#layout #content .layout-content #main .grid-row.wrap .grid-column {
  padding-left: 0px;
}
#layout #content .layout-content #main .grid-column:first-child {
  padding-left: 0px;
}
.col-2,
.col-3,
.col-4,
.col-5 {
  padding: 5px 0;
}

.patient-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 2rem;
  order: 2;
  list-style: none;
  max-width: 300px;
}
.patient-info ul {
  margin: 0;
  padding: 0;
}
.patient-info ul li {
  margin: 0;
  padding: 15px;
  color: #02385d;
  background: #dfecf5;
  line-height: 24px;
}
.patient-info ul li.header {
  margin: 0;
  padding: 20px 15px;
  line-height: 24px;
  color: white;
  background: #0164a7;
}
.patient-info ul li.header ul li {
  margin: 0;
  padding: 0;
  color: white;
  background: #0164a7;
  font-size: 18px;
}
.patient-info ul li:nth-child(2n) {
  margin: 0;
  background: #cde0ee;
}
.patient-info ul li:nth-child(2n) ul li {
  margin: 0;
  padding: 0px;
  background: #cde0ee;
}
.patient-info ul li ul li {
  margin: 0;
  padding: 0px;
  color: #454552;
}
.patient-info-table {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
}
.patient-info-table .patient-info-cell {
  padding: 10px 50px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 0.5px solid #cbd1d7;
  width: 30%;
}
.patient-info-table .patient-info-cell:nth-child(2n) {
  width: 70%;
  background-color: #f1f1f1;
}
.patient-info-table .patient-info-cell.header {
  color: white;
  background-color: #0164a7;
}
.patient-info-table-button {
  margin: 2rem 24rem !important;
  padding: 0.5rem 3.5rem !important;
}

#patient-request {
  width: 100%;
}

#patient-request .request-picker {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  font-size: 16px;
}

#patient-request .request-picker span {
  font-weight: bold;
  margin-right: 10px;
}

#patient-request .request-picker div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border-right: 1px solid #000000;
  cursor: pointer;
  font-weight: bold;
}

#patient-request .request-picker div:hover {
  color: #0164a7;
}

#patient-request .request-picker div:last-child {
  border-right: none;
}

#patient-request .patient-requests-list-wrapper {
  width: 100%;
  margin: 20px auto;
}

#patient-request .patient-requests-list-wrapper .header {
  background-color: #0164a7;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
}

#patient-request .patient-requests-list-wrapper .title {
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #b0b0b6;
  border-right: 1px solid #b0b0b6;
  border-left: 1px solid #b0b0b6;
}

#patient-request .patient-requests-list-wrapper .full {
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #b0b0b6;
  border-right: 1px solid #b0b0b6;
  border-left: 1px solid #b0b0b6;
}

#patient-request .patient-requests-list-wrapper .content {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b0b0b6;
  border-right: 1px solid #b0b0b6;
}

#patient-request .patient-requests-list-wrapper .content .input {
  width: 100%;
}

#patient-request .patient-requests-list-wrapper a {
  color: #0164a7 !important;
  text-decoration: none;
}

#patient-request .request-button-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
}

/* CARE PROCEDURES TABLE */
.care-procedures {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-width: 300px;
  font-size: 18px;
}
.care-procedures table {
  border: 1px solid #ccd2d7;
}
.care-procedures table tr {
  border: 1px solid #ccd2d7;
  padding: 1rem 0;
}
.care-procedures table tr td {
  border: 1px solid #ccd2d7;
  padding: 1rem 2rem;
}

/* CARE PROCEDURES TABBLE PHARMACOLOGICAL PLAN */
.care-procedures.pharmacological-plan {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: none;
  font-size: 18px;
  margin: 0 0 2rem 0;
  width: 100%;
}
.care-procedures.pharmacological-plan .header {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

/* PATIENT LOOKUP */
#layout #content .layout-content #main .patient-search .myTable {
  font-size: 14px;
}
#layout #content .layout-content #main .patient-search .myTable thead tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout #content .layout-content #main .patient-search .myTable thead tr th {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 15%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 15%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout #content .layout-content #main .patient-search .myTable tbody tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 15%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 20%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 15%;
  justify-content: center;
  padding-left: 10px;
}
#layout #content .layout-content #main .patient-search .myTable tbody tr td {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
#layout
  #content
  .layout-content
  #main
  .patient-search
  .myTable
  tbody
  tr
  td
  button {
  padding: 0 3px;
  margin: 3px 2px;
  min-width: auto;
  font-size: 14px;
  line-height: 24px;
}

#layout #content .layout-content #main .patient-entry-control {
  display: flex;
}
#layout #content .layout-content #main .patient-entry-control .patient-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 30px;
  order: 2;
  list-style: none;
  max-width: 300px;
}
#layout #content .layout-content #main .patient-entry-control .patient-info ul {
  margin: 0;
  padding: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .patient-info
  ul
  li {
  margin: 0;
  padding: 15px;
  color: #02385d;
  background: #dfecf5;
  line-height: 24px;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .patient-info
  ul
  li.header {
  margin: 0;
  padding: 20px 15px;
  line-height: 24px;
  color: white;
  background: #0164a7;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .patient-info
  ul
  li.header
  ul
  li {
  margin: 0;
  padding: 0;
  color: white;
  background: #0164a7;
  font-size: 18px;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .patient-info
  ul
  li:nth-child(2n) {
  margin: 0;
  background: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .patient-info
  ul
  li:nth-child(2n)
  ul
  li {
  margin: 0;
  padding: 0px;
  background: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .patient-info
  ul
  li
  ul
  li {
  margin: 0;
  padding: 0px;
  color: #454552;
}
#layout #content .layout-content #main .patient-entry-control .input-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 14px;
}
#layout #content .layout-content #main .patient-entry-control .form-row {
  display: flex;
  margin-bottom: 5px;
  padding: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .form-row:nth-child(2n) {
  display: flex;
  margin-bottom: 5px;
  background: #dfecf5;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .form-row:last-child {
  display: flex;
  margin-bottom: 5px;
  background: white;
}
#layout
  #content
  .layout-content
  #main
  .patient-entry-control
  .input-group
  > label {
  flex: 1;
  width: 135px;
  max-width: 165px;
  margin-right: 0;
  margin-left: 53px;
  align-self: center;
}
#layout #content .layout-content #main .patient-entry-control .form-row-radio {
  display: flex;
  margin-bottom: 30px;
  margin-left: 0px;
  padding: 10px 42px;
  background: #dfecf5;
}
#layout #content .layout-content #main .form-row-radio input {
  display: none;
}
#layout #content .layout-content #main .form-row-radio input + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #06c102;
  margin-left: 30px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
#layout
  #content
  .layout-content
  #main
  .form-row-radio
  input:checked
  + *::before {
  background: radial-gradient(
    #06c102 0%,
    #06c102 40%,
    transparent 50%,
    transparent
  );
  border-color: #06c102;
}
#layout
  #content
  .layout-content
  #main
  .form-row-button
  .button.patient-info-button {
  margin: 20px auto;
  padding: 0 30px;
  width: 270px;
}
#layout #content .layout-content #main .patient-entry-control .inp-btn-add-rem {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 40px;
}

#layout #content .layout-content #main .patient-hospital {
  display: flex;
}
#layout #content .layout-content #main .patient-hospital .legend ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  flex-wrap: wrap;
}
#layout #content .layout-content #main .patient-hospital .legend {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  list-style: none;
  width: 100%;
  font-size: 12px;
}
#layout #content .layout-content #main .patient-hospital .legend ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 25px;
  margin-right: 12px;
}
#layout #content .layout-content #main .patient-hospital .legend ul li::before {
  content: "\25A0";
  display: inline-block;
  margin-right: 10px;
  font-size: 25px;
  margin-bottom: 9px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(1)::before {
  color: #454552;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(2)::before {
  color: #279112;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(3)::before {
  color: #eca100;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(4)::before {
  color: #a63a49;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(5)::before {
  color: #0164a7;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(6)::before {
  display: none;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(6)
  span {
  color: #a63a49;
  font-size: 20px;
  margin-right: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(7)::before {
  display: none;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(7)
  span {
  color: #b0b0b6;
  font-size: 20px;
  margin-right: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(8)::before {
  display: none;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .legend
  ul
  li:nth-child(8)
  span {
  color: #b0b0b6;
  font-size: 16px;
  margin-right: 10px;
}
#layout #content .layout-content #main .patient-hospital .legend span {
  font-size: 18px;
  color: #02385d;
  display: block;
}
#layout #content .layout-content #main .patient-hospital a {
  color: #ffffff;
}
#layout #content .layout-content #main .patient-hospital a:hover {
  color: #eca100;
}
#layout #content .layout-content #main .patient-hospital .grid-row:first-child {
  max-width: 824px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row
  > .grid-column
  > .grid-row
  > .grid-column:nth-child(2) {
  padding-left: 10px;
}
#layout #content .layout-content #main .patient-hospital .input-group {
  flex-grow: 2;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .input-group
  .inp-text::placeholder {
  font-style: italic;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .input-group:nth-of-type(2) {
  background-color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .input-group:first-child
  > label {
  max-width: fit-content;
  margin-bottom: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .input-group:first-child
  > label
  + div {
  margin-left: 10px;
}
#layout #content .layout-content #main .patient-hospital .grid-row-checkbox {
  display: flex;
  margin: 10px 0 30px 0;
}
#layout #content .layout-content #main .patient-hospital .grid-row-checkbox * {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row-checkbox
  > label {
  margin-left: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row-checkbox
  div:first-child {
  padding: 0 10px;
  margin-left: 20px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row-checkbox
  div
  input {
  appearance: none;
  background-color: #fff;
  display: grid;
  place-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 3px solid #ccd3d9;
  border-radius: 0.15em;
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row-checkbox
  div
  input::before {
  content: "";
  height: 12.5px;
  width: 12.5px;
  transform: scale(0);
  border-radius: 0.1em;
  box-shadow: inset 1em 1em #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row-checkbox
  div
  input:checked::before {
  transform: scale(1);
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .grid-row-checkbox
  div
  label {
  margin-right: 25px;
}
#layout #content .layout-content #main .patient-hospital .myTable {
  font-size: 14px;
}
#layout #content .layout-content #main .patient-hospital .myTable thead tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout #content .layout-content #main .patient-hospital .myTable thead tr th {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 3%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 6%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 13%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(7) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(8) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(9) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(10) {
  width: 12%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:nth-child(11) {
  width: 17.8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th:last-child {
  display: none;
  width: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout #content .layout-content #main .patient-hospital .myTable tbody tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 3%;
  background-color: #fff;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 6%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 13%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(7) {
  width: 9%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(8) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(9) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(10) {
  width: 12%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(11) {
  width: 17.8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td:nth-child(12) {
  display: none;
  width: 0;
}
#layout #content .layout-content #main .patient-hospital .myTable tbody tr td {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .myTable
  tbody
  tr
  td
  button {
  padding: 0 3px;
  margin: 3px 2px;
  min-width: auto;
  font-size: 14px;
  line-height: 24px;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control:active {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control
  input:focus {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital
  .form-column.patient-past-select
  .input-group
  input {
  margin-right: 1rem;
}

#layout #content .layout-content #main .penfast-hospital {
  display: flex;
}
#layout #content .layout-content #main .penfast-hospital .legend ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  flex-wrap: wrap;
}
#layout #content .layout-content #main .penfast-hospital .legend {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  list-style: none;
  width: 100%;
  font-size: 12px;
}
#layout #content .layout-content #main .penfast-hospital .legend ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 25px;
  margin-right: 12px;
}
#layout #content .layout-content #main .penfast-hospital .legend ul li::before {
  content: "\25A0";
  display: inline-block;
  margin-right: 10px;
  font-size: 25px;
  margin-bottom: 9px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .legend
  ul
  li:nth-child(1)::before {
  color: #a63a49;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .legend
  ul
  li:nth-child(2)::before {
  color: #eca100;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .legend
  ul
  li:nth-child(3)::before {
  color: #279112;
}

#layout #content .layout-content #main .penfast-hospital .legend span {
  font-size: 18px;
  color: #02385d;
  display: block;
}
#layout #content .layout-content #main .penfast-hospital a {
  color: #ffffff;
}
#layout #content .layout-content #main .penfast-hospital a:hover {
  color: #eca100;
}
#layout #content .layout-content #main .penfast-hospital .grid-row:first-child {
  max-width: 824px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row
  > .grid-column
  > .grid-row
  > .grid-column:nth-child(2) {
  padding-left: 10px;
}
#layout #content .layout-content #main .penfast-hospital .input-group {
  flex-grow: 2;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .input-group
  .inp-text::placeholder {
  font-style: italic;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .input-group:nth-of-type(2) {
  background-color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .input-group:first-child
  > label {
  max-width: fit-content;
  margin-bottom: 0;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .input-group:first-child
  > label
  + div {
  margin-left: 10px;
}
#layout #content .layout-content #main .penfast-hospital .grid-row-checkbox {
  display: flex;
  margin: 10px 0 30px 0;
}
#layout #content .layout-content #main .penfast-hospital .grid-row-checkbox * {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row-checkbox
  > label {
  margin-left: 0;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row-checkbox
  div:first-child {
  padding: 0 10px;
  margin-left: 20px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row-checkbox
  div
  input {
  appearance: none;
  background-color: #fff;
  display: grid;
  place-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 3px solid #ccd3d9;
  border-radius: 0.15em;
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row-checkbox
  div
  input::before {
  content: "";
  height: 12.5px;
  width: 12.5px;
  transform: scale(0);
  border-radius: 0.1em;
  box-shadow: inset 1em 1em #02385d;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row-checkbox
  div
  input:checked::before {
  transform: scale(1);
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .grid-row-checkbox
  div
  label {
  margin-right: 25px;
}
#layout #content .layout-content #main .penfast-hospital .myTable {
  font-size: 14px;
}
#layout #content .layout-content #main .penfast-hospital .myTable thead tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout #content .layout-content #main .penfast-hospital .myTable thead tr th {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
/* #layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 3%;
} */
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 6%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 13%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(7) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(8) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(9) {
  width: 12%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:nth-child(10) {
  width: 17.8%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th:last-child {
  display: none;
  width: 0;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout #content .layout-content #main .penfast-hospital .myTable tbody tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
/* #layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 3%;
  background-color: #fff;
} */
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 6%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 13%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 9%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(7) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(8) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(9) {
  width: 12%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(10) {
  width: 17.8%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td:nth-child(11) {
  display: none;
  width: 0;
}
#layout #content .layout-content #main .penfast-hospital .myTable tbody tr td {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .myTable
  tbody
  tr
  td
  button {
  padding: 0 3px;
  margin: 3px 2px;
  min-width: auto;
  font-size: 14px;
  line-height: 24px;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control:active {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control
  input:focus {
  margin-left: 2.2rem;
}

#layout
  #content
  .layout-content
  #main
  .penfast-hospital
  .form-column.patient-past-select
  .input-group
  input {
  margin-right: 1rem;
}

#layout #content .layout-content #main .requisitions .myTable {
  font-size: 14px;
}
#layout #content .layout-content #main .requisitions .myTable thead tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout #content .layout-content #main .requisitions .myTable thead tr th {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border-left: 1px solid #ccd3d9;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 6%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(7) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(8) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(9) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(10) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:nth-child(11) {
  width: 20%;
  border-right: 1px solid #ccd3d9;
}

#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th:last-child {
  display: none;
  width: 0;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout #content .layout-content #main .requisitions .myTable tbody tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}

#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 6%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(7) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(8) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(9) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(10) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(11) {
  width: 20%;
  justify-content: space-around;
  border-right: 1px solid #ccd3d9;
}

#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td:nth-child(12) {
  display: none;
  width: 0;
}

#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr:last-child {
  border-bottom: 1px solid #ccd3d9;
}
#layout #content .layout-content #main .requisitions .myTable tbody tr td {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ccd3d9;
  border-top: 1px solid #ccd3d9;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .myTable
  tbody
  tr
  td
  button {
  padding: 0 3px;
  margin: 3px 2px;
  min-width: auto;
  font-size: 14px;
  line-height: 24px;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control:active {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control
  input:focus {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .requisitions
  .form-column.patient-past-select
  .input-group
  input {
  margin-right: 1rem;
}

#layout #content .layout-content #main .patient-external {
  display: flex;
}
#layout #content .layout-content #main .patient-external .legend ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  flex-wrap: wrap;
}
#layout #content .layout-content #main .patient-external .legend {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  list-style: none;
  width: 100%;
}
#layout #content .layout-content #main .patient-external .legend ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  margin-right: 12px;
}
#layout #content .layout-content #main .patient-external .legend ul li::before {
  content: "\25A0";
  display: inline-block;
  margin-right: 10px;
  font-size: 40px;
  margin-bottom: 9px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(1)::before {
  color: #454552;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(2)::before {
  color: #279112;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(3)::before {
  color: #eca100;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(4)::before {
  color: #a63a49;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(5)::before {
  color: #0164a7;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(6)::before {
  display: none;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(6)
  span {
  color: #a63a49;
  font-size: 24px;
  margin-right: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(7)::before {
  display: none;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .legend
  ul
  li:nth-child(7)
  span {
  color: #b0b0b6;
  font-size: 24px;
  margin-right: 10px;
}
#layout #content .layout-content #main .patient-external .legend span {
  font-size: 18px;
  color: #02385d;
  display: block;
}
#layout #content .layout-content #main .patient-external a {
  color: #ffffff;
}
#layout #content .layout-content #main .patient-external a:hover {
  color: #eca100;
}
#layout #content .layout-content #main .patient-external .grid-row:first-child {
  max-width: 824px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row
  > .grid-column
  > .grid-row
  > .grid-column:nth-child(2) {
  padding-left: 10px;
}
#layout #content .layout-content #main .patient-external .input-group {
  flex-grow: 2;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .input-group
  .inp-text::placeholder {
  font-style: italic;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .input-group:nth-of-type(2) {
  background-color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .input-group:first-child
  > label {
  max-width: fit-content;
  margin-bottom: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .input-group:first-child
  > label
  + div {
  margin-left: 10px;
}
#layout #content .layout-content #main .patient-external .grid-row-checkbox {
  display: flex;
  margin: 10px 0 30px 0;
}
#layout #content .layout-content #main .patient-external .grid-row-checkbox * {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row-checkbox
  > label {
  margin-left: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row-checkbox
  div:first-child {
  padding: 0 10px;
  margin-left: 20px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row-checkbox
  div
  input {
  appearance: none;
  background-color: #fff;
  display: grid;
  place-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 3px solid #ccd3d9;
  border-radius: 0.15em;
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row-checkbox
  div
  input::before {
  content: "";
  height: 12.5px;
  width: 12.5px;
  transform: scale(0);
  border-radius: 0.1em;
  box-shadow: inset 1em 1em #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row-checkbox
  div
  input:checked::before {
  transform: scale(1);
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .grid-row-checkbox
  div
  label {
  margin-right: 25px;
}
#layout #content .layout-content #main .patient-external .myTable {
  font-size: 14px;
}
#layout #content .layout-content #main .patient-external .myTable thead tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout #content .layout-content #main .patient-external .myTable thead tr th {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(7) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:nth-child(8) {
  width: 15%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th:last-child {
  display: none;
  width: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout #content .layout-content #main .patient-external .myTable tbody tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 10%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 8%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 9%;
  justify-content: flex-start;
  padding-left: 10px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(7) {
  width: 5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(8) {
  width: 15%;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td:nth-child(9) {
  display: none;
  width: 0;
}
#layout #content .layout-content #main .patient-external .myTable tbody tr td {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .myTable
  tbody
  tr
  td
  button {
  padding: 0 3px;
  margin: 3px 2px;
  min-width: auto;
  font-size: 14px;
  line-height: 24px;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control:active {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .form-row.patient-past-select
  .input-group
  .css-4tpj10-control
  input:focus {
  margin-left: 2.2rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-external
  .form-column.patient-past-select
  .input-group
  input {
  margin-right: 1rem;
}

.td-filter {
  padding-left: 0 !important;
  flex-direction: column;
}

#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 30%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th:last-child {
  display: none;
  width: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 30%;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-hospital.patient-past
  .myTable
  tbody
  tr
  td:last-child {
  width: 0;
}

#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 30%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th:last-child {
  display: none;
  width: 0;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  thead
  tr
  th
  span:last-child {
  color: #96a6b0;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 30%;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .penfast-hospital.penfast-past
  .myTable
  tbody
  tr
  td:last-child {
  width: 0;
}

/* PATINET PROFILE */
#layout #content .layout-content #main .patient-profile {
  display: flex;
}
#layout #content .layout-content #main .patient-profile .grid-column {
  flex-direction: row;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-column:first-child
  div.iconMoreInfo {
  display: flex;
  flex-direction: column-reverse;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-column:first-child
  div.iconMoreInfo
  svg {
  font-size: 30px;
  margin-left: 20px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-column.patient-checkin-table {
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-column.patient-checkin-table
  .grid-column {
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr:first-child {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #eca100;
  color: white;
}
#layout #content .layout-content #main .patient-profile .myTable thead tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 10%;
  display: flex;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 11%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(7) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(8) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(9) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(10) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  thead
  tr
  th:nth-child(11) {
  width: 8%;
}
#layout #content .layout-content #main .patient-profile .myTable thead tr th {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
#layout #content .layout-content #main .patient-profile .myTable tbody tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 11%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(7) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(8) {
  width: 7%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(9) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(10) {
  width: 9%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .myTable
  tbody
  tr
  td:nth-child(11) {
  width: 8%;
}
#layout #content .layout-content #main .patient-profile .myTable tbody tr td {
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid #ccd2d7;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
#layout #content .layout-content #main .patient-profile .myForm {
  margin-top: 2rem;
}
#layout #content .layout-content #main .patient-profile .input-group > label {
  flex: 1;
  max-width: 250px;
  margin-right: 0px;
  align-self: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .input-group
  .css-3iigni-container {
  border: 1px solid #ccd2d7;
}
#layout #content .layout-content #main .patient-profile .grid-row.comments {
  align-items: flex-end;
  max-width: 1450px;
}
#layout #content .layout-content #main .patient-profile .grid-column.comments {
  flex-direction: column;
  align-items: flex-end;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-column.comments
  .comment {
  width: 85%;
}
#layout #content .layout-content #main .form-row-button {
  max-width: 1470px;
}

#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table {
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  thead
  tr:first-child {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #eca100;
  color: white;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  thead
  tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  thead
  tr
  th:nth-child(1) {
  width: 20%;
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  thead
  tr
  th:nth-child(2) {
  width: 70%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  thead
  tr
  th:nth-child(3) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  thead
  tr
  th {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  tbody
  tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  tbody
  tr
  td:nth-child(1) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  tbody
  tr
  td:nth-child(2) {
  width: 70%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  tbody
  tr
  td:nth-child(3) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .patient-information-table
  tbody
  tr
  td {
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid #ccd2d7;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* PHARMACOLOGICAL PLAN GO BACK BTN */
pharma-plan-goback-btn
  #layout
  #content
  .layout-content
  #main
  .pharma-plan-goback-btn {
  text-align: right;
}
#layout #content .layout-content #main .pharma-plan-goback-btn button {
  float: right;
  margin-top: -5rem;
}

/* PHARMACOLOGICAL PLAN */
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-column {
  flex-direction: column;
  flex-wrap: wrap;
  flex: 3 1;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .sub-page-navigation.present {
  margin: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row:nth-of-type(2) {
  position: relative;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row:nth-of-type(2)
  img {
  position: absolute;
  top: 5px;
  right: 15px;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  cursor: pointer;
}
/* #layout #content .layout-content #main .patient-profile.pharmacological-plan .myForm .form-row:nth-of-type(2) span { position: absolute; top: 45px; right: 15px;} */
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row:nth-of-type(3) {
  position: relative;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row:nth-of-type(3)
  img {
  position: absolute;
  top: 5px;
  right: 15px;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  cursor: pointer;
}
/* #layout #content .layout-content #main .patient-profile.pharmacological-plan .myForm .form-row:nth-of-type(3) span { position: absolute; top: 45px; right: 15px;} */
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row
  .form-column.pharma-verifiation-checkbox {
  justify-content: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row
  .form-column.pharma-verifiation-checkbox
  .input-group
  input {
  margin-bottom: 0;
  transform: scale(1.5);
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row
  .form-column.pharma-verifiation-checkbox
  .input-group
  label {
  margin-bottom: 0;
  margin-left: 1rem;
  font-weight: bold;
}

/* PHARMA TABLE */
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr:first-child {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #dfecf5;
  color: #454552;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  background-color: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(6) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(7) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(8) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(9) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(10) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(11) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(12) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th:nth-child(13) {
  width: 7.5%;
  justify-content: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  thead
  tr
  th {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(6) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(7) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(8) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(9) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(10) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(11) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(12) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td:nth-child(13) {
  width: 7.5%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myTable
  tbody
  tr
  td {
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid #ccd2d7;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* PHARMA FORM */
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm:first-of-type {
  margin-top: 0;
  padding-left: 5px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column.tags-date:first-of-type {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column.tags-date:last-of-type {
  flex: 1 1 40%;
  align-items: center;
  padding-left: 1rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column.tags-date:last-of-type
  .input-group
  > label {
  align-self: center;
  margin-bottom: 0;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column
  .tag-row {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column
  .tag-inp {
  display: flex;
  width: 80%;
  justify-content: space-between;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column
  .tag-inp
  > div {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .form-column
  .tag-inp
  > div
  > div {
  width: 90%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row
  .pharmacological-text-inp {
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row.text-inp {
  min-height: 300px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row.text-inp
  > .pharmacological-text-inp
  .quill {
  min-height: 300px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row.text-inp
  > .pharmacological-text-inp
  .quill
  .ql-container.ql-snow {
  min-height: 300px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .pharmacological-text-inp
  .quill
  .ql-container.ql-snow {
  min-height: 300px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .myForm
  .form-row.text-inp
  > .pharmacological-text-inp
  .quill
  .ql-container.ql-snow
  .ql-editor {
  min-height: 300px;
}

.ql-editor {
  min-height: 300px;
}

/* PHARMA RIGHT COMPONENT */
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin {
  width: 100%;
  border: 1px solid #ccd2d7;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .header {
  color: white;
  font-weight: 700;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row {
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #dfecf5;
  margin-bottom: 3px;
  min-height: 3rem;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row:first-child {
  justify-content: space-around;
  padding: 0 1rem;
  background-color: #dfecf5;
  margin-bottom: 1px;
  min-height: 3rem;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row:first-child
  span {
  flex: 2 2 80%;
  text-align: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row:first-child
  button {
  flex: 1 1 20%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .checkin-more-info
  svg {
  font-size: 30px;
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row.checkin-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 1rem;
  background-color: white;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row.checkin-info
  p {
  font-style: italic;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .pharma-plan-checkin
  .grid-row.checkin-info
  ul {
  margin: 1rem 0;
  list-style-type: disc;
  padding: 0 3rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row.notes {
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row
  .inp-textarea {
  width: 100%;
  margin-top: 1rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row.notes
  span {
  font-size: 18px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row.note-input
  div {
  display: flex;
  gap: 0.5rem;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row.note-input
  div
  textarea {
  line-height: 1.5;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row.note-input
  div
  p {
  flex: 1 1 90%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.pharmacological-plan
  .grid-row.note-input
  div
  div.text-container-more-info {
  flex: 1 1 10%;
}

/* FTD EVALUATION */
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-row.ftd-evaluation {
  justify-content: space-between;
  border-bottom: 1px solid #e5e8eb;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-row.ftd-evaluation
  div {
  display: flex;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-row.ftd-evaluation
  div
  span {
  font-size: 18px;
  color: #0164a7;
  font-weight: bold;
  border-bottom: 0px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile
  .grid-row.ftd-evaluation
  .iconMoreInfo
  svg {
  font-size: 30px;
  margin-left: 20px;
}

/* COMMENT */
#layout #content .layout-content #main .commentHeader {
  display: flex;
  align-items: center;
  padding: 10px;
}
#layout #content .layout-content #main .commentHeader i {
  font-size: 50px;
  padding: 10px;
}
#layout #content .layout-content #main .commentHeader span {
  padding: 10px;
}
#layout #content .layout-content #main .commentHeader span.commentName {
  color: #02385d;
  border-bottom: 1px solid #ccd2d7;
}
#layout #content .layout-content #main .commentHeader div.delete-icon {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem 0 1rem 0;
  font-size: 30px;
  color: #02385d;
  float: right;
}
#layout
  #content
  .layout-content
  #main
  .commentHeader
  div.delete-icon
  > span:hover {
  color: #0164a7;
}
#layout #content .layout-content #main .comment .error {
  display: flex;
  justify-content: flex-end;
}
#layout #content .layout-content #main .commentBody {
  padding: 0 80px;
  width: 90%;
}

/* PHARMA PLAN "COMMENTS" TEXT CONTAINERS */
#layout #content .layout-content #main .text-container {
  display: flex;
  flex-flow: row nowrap;
  background-color: #e5e8eb;
  padding: 2rem;
  gap: 1rem;
}
#layout #content .layout-content #main .text-container-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 10%;
  gap: 1rem;
}
#layout #content .layout-content #main .text-container-title span {
  color: #02385d;
}
#layout #content .layout-content #main .text-container-title img {
  width: 3rem;
  height: auto;
}
#layout #content .layout-content #main .text-container-body {
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 80%;
}
#layout #content .layout-content #main .text-container-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1 1 10%;
  gap: 1rem;
}
#layout #content .layout-content #main .text-container-btns-btns {
  flex: 1 1 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;
}
#layout #content .layout-content #main .text-container-more-info {
  flex: 1 1 20%;
}
#layout #content .layout-content #main .text-container-more-info svg {
  font-size: 30px;
  color: #02385d;
}
#layout #content .layout-content #main .text-container-header {
  display: flex;
  align-items: center;
}
#layout #content .layout-content #main .text-container-header i {
  font-size: 50px;
  padding: 0px;
}
#layout #content .layout-content #main .text-container-header span.commentName {
  color: #02385d;
  border-bottom: 1px solid #ccd2d7;
}

/* PATIENT HISTORY */
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-container {
  border-bottom: 1px solid #e5e8eb;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-title
  span {
  font-size: 18px;
  color: #0164a7;
  font-weight: bold;
  border-bottom: 0px;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-container
  div:last-of-type
  svg {
  font-size: 2rem;
  color: #454552;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-container
  .flex-container-row {
  width: 80%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-content
  .flex-container-row.history-changes {
  justify-content: space-around;
  width: 80%;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-container
  .content-history-label {
  font-size: 2rem;
  color: #454552;
}
#layout
  #content
  .layout-content
  #main
  .patient-profile.patient-history
  .myContent
  .care-history-container
  .content-history-value {
  font-size: 2rem;
  color: #454552;
}

/* NEW PATIENT FORM */
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .patient-form-checkbox {
  margin-bottom: 0;
  padding: 0;
}
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .input-group
  label
  + .input {
  display: flex;
  flex: 2 1;
}
#layout #content .layout-content #main .myContent.patient-form .form-title h2 {
  display: flex;
  justify-content: space-between;
}
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .form-title
  h2
  svg {
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .add-representative
  .add-representative-icon {
  display: flex;
  align-items: center;
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .add-representative
  .add-representative-icon
  svg {
  color: #02385d;
  font-size: 2rem;
}
#layout #content .layout-content #main .myContent.patient-form .form-container {
  border-bottom: 1px solid #e5e8eb;
}
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .communication-input-container {
  width: 50%;
  margin-left: 5.6rem;
}
#layout
  #content
  .layout-content
  #main
  .myContent.patient-form
  .communication-input-container
  label {
  max-width: 250px;
}

/* SIDE EFFECTS */
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .patient-form-checkbox {
  margin-bottom: 0;
  padding: 0;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .input-group
  label
  + .input {
  display: flex;
  flex: 2 1;
}
#layout #content .layout-content #main .myContent.side-effect .form-title h2 {
  display: flex;
  justify-content: space-between;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .form-title
  h2
  svg {
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .add-representative
  .add-representative-icon {
  display: flex;
  align-items: center;
  color: #02385d;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .add-representative
  .add-representative-icon
  svg {
  color: #02385d;
  font-size: 2rem;
}
#layout #content .layout-content #main .myContent.side-effect .form-container {
  border-bottom: 1px solid #e5e8eb;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .communication-input-container {
  width: 50%;
  margin-left: 5.6rem;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .communication-input-container
  label {
  max-width: 250px;
}
#layout #content .layout-content #main .myContent.side-effect .patient-death {
  display: flex;
  align-items: baseline;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .side-effect-last-form-part {
  width: 80%;
  display: flex;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .side-effect-last-form-part
  label {
  max-width: 450px;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .side-effect-last-form-part
  #new_drug_nonaplicable {
  color: red;
}

#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-header {
  display: flex;
  align-items: center;
  background-color: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-header
  label {
  margin-bottom: 0;
  font-weight: bold;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-header
  div {
  display: flex;
  flex: 1 1 10%;
  align-items: center;
  padding: 1rem;
  border-left: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-header
  div:first-of-type {
  flex: 1 1 20%;
  border-left: none;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-body {
  display: flex;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-body
  .cell {
  display: flex;
  flex: 1 1 10%;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .drug-table-input.table-wrapper
  .table-body
  .cell:first-of-type {
  flex: 1 1 20%;
}

#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper {
  width: 100%;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-header {
  display: flex;
  align-items: center;
  background-color: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-header
  label {
  margin-bottom: 0;
  font-weight: bold;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-header
  div {
  display: flex;
  flex: 1 1 10%;
  align-items: center;
  padding: 1rem;
  border-left: 1px solid white;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-header
  div:first-of-type {
  flex: 1 1 20%;
  border-left: none;
}

#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-body {
  display: flex;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-body
  .cell {
  display: flex;
  flex: 1 1 10%;
  align-items: center;
}
#layout
  #content
  .layout-content
  #main
  .myContent.side-effect
  .secondary-drug-table-input.table-wrapper
  .table-body
  .cell:first-of-type {
  flex: 1 1 20%;
}

/* HELP PAGE */
.help img {
  display: block;
  margin-bottom: 32px;
}

.help .link {
  display: block;
  font-size: 22px;
  color: rgb(182, 21, 63);
  margin-bottom: 8px;
}

.help p {
  margin-bottom: 22px;
}

.help .title {
  display: block;
  text-decoration: underline;
}

.pagination {
  width: 100%;
  margin-top: 22px;
  flex-wrap: wrap;
}

/* Table */
#layout #content .layout-content #main .myTable {
  width: 100%;
}

/* External patients */
#layout #content .layout-content #main .patients-external-table .myTable {
  width: 80vw;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  thead
  tr
  th:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  thead
  tr
  th:nth-child(2) {
  width: 30%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  thead
  tr
  th:nth-child(3) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  thead
  tr
  th:nth-child(4) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  thead
  tr
  th:nth-child(5) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  tbody
  tr
  td:nth-child(1) {
  width: 10%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  tbody
  tr
  td:nth-child(2) {
  width: 30%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  tbody
  tr
  td:nth-child(3) {
  width: 20%;
}
e
  #layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  tbody
  tr
  td:nth-child(4) {
  width: 20%;
}
#layout
  #content
  .layout-content
  #main
  .patients-external-table
  .myTable
  tbody
  tr
  td:nth-child(5) {
  width: 20%;
}

/* Tags */
#layout #content .layout-content #main .icons {
  position: absolute;
  top: 15px;
  right: 15px;
}
#layout #content .layout-content #main .icons > img {
  height: 50%;
  width: 50%;
}
#layout #content .layout-content #main .grid-column.tag {
  flex-basis: 20%;
}
#layout #content .layout-content #main .grid-column.tag label {
  display: none;
}
#layout #content .layout-content #main .grid-column.tag .input-group {
  padding: 0;
}
#layout
  #content
  .layout-content
  #main
  .grid-column.tag
  .input-group
  .inp-text.tag {
  margin: 0 1.5rem;
  border: none;
}
#layout
  #content
  .layout-content
  #main
  .grid-column.tag:nth-of-type(odd)
  .input-group
  .inp-text.tag {
  background-color: #cde0ee;
}
#layout
  #content
  .layout-content
  #main
  .grid-column.tag
  .input-group
  .inp-text.tag {
  background-color: white;
}
/* #layout #content .layout-content #main .grid-column.tag .input-group .inp-text.tag:disabled { background-color: white; } */

/* Tag block */
#layout #content .layout-content #main .tag {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: #02385d;
  padding: 0.5rem;
  border-radius: 0.3rem;
}
#layout #content .layout-content #main .tag span {
  color: white;
}
#layout #content .layout-content #main .tag svg {
  color: white;
  font-size: 1.5rem;
}

/*  */
#layout #content .layout-content #main .date-picker-container {
  position: relative;
}
#layout #content .layout-content #main .date-picker-container > svg {
  position: absolute;
  top: 13px;
  right: 15px;
  color: #02385d;
  font-size: 1rem;
}

/* Pop ups */
#layout #content .layout-content #main .modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: block;
}
#layout #content .layout-content #main .modal_content {
  text-align: center;
  padding: 30px;
  margin: 10em auto;
  background-color: #eca100;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
#layout #content .layout-content #main .modal_content .text p {
  margin-bottom: 30px;
}
#layout #content .layout-content #main .modal_content .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 22px auto;
}
#layout
  #content
  .layout-content
  #main
  .modal_content
  .btn-row.flex-container-row {
  justify-content: space-around;
  gap: 1rem;
}

.modal-content-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 100%;
  height: 50px;
  background-color: #f1f1f1;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
}
.table-icon {
  color: #02385d;
  font-size: 20px;
  cursor: pointer;
}

.react-datepicker__close-icon {
  right: 30px !important;
}

.adverse-effect-table thead {
  background-color: #cde0ee;
}

.adverse-effect-table tbody td {
  padding: 0;
}

.predefeined-text-wrapper {
  margin-bottom: 16px;
  border-bottom: 1px solid #ccd2d7;
}

.predefeined-text-item {
  display: flex;
  justify-content: space-between;
  color: #0164a7;
}

.predefeined-text-item label {
  color: #0164a7;
  font-weight: bold;
  width: 95%;
  cursor: pointer;
}

.predefeined-text-item i {
  cursor: pointer;
}

.predefeined-text-wrapper .expand {
  display: inline-block;
}

.predefined-textarea .quill .ql-container.ql-snow {
  min-height: 300px;
}

.pharmaplan-predefined-btn {
  text-align: right;
  margin-top: 16px;
}

.predefined-text-modal-content {
  background-color: #ffffff;
  text-align: center;
  padding: 30px;
  margin: 10em auto;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  width: 80%;
  overflow-y: scroll;
}

.predefined-text-modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e8eb;
}

.predefined-text-modal-header h1 {
  border-bottom: none !important;
  margin: 0 !important;
}

.predefined-text-modal-header i {
  font-size: 22px;
  color: #02385d;
}

.predefined-text-modal-table-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.predefined-text-modal-table-text .text {
  width: 90%;
}

.predefined-text-modal-table-text svg {
  color: #02385d;
  font-size: 22px;
}

.anamneza .title {
  color: #0164a7;
  font-weight: bold;
}

.anamneza .weight-history-graph {
  border: 1px solid #ccd2d7;
  width: 100%;
  -webkit-box-shadow: 10px 10px 25px 0px rgba(204, 210, 215, 0.79);
  -moz-box-shadow: 10px 10px 25px 0px rgba(204, 210, 215, 0.79);
  box-shadow: 10px 10px 25px 0px rgba(204, 210, 215, 0.79);
}

.table-container {
  width: 100%;
  max-width: 600px;
}

.anamneza-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.anamneza-table th {
  background-color: #cde0ee;
  padding: 10px;
  font-weight: bold;
  border: 1px solid #ccc;
}

.anamneza-table td {
  height: 50px;
  border: 1px solid #ccc;
}

.anamneza-multiple-select {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.anamneza-multiple-select .input-icons {
  margin-left: 10px;
  font-size: 30px;
  color: #02385d;
}

.penfast .switch-comp {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.penfast .title {
  color: #0164a7;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* General tweeks */
@media only screen and (max-width: 1280px) {
  h1 {
    font-size: 4.5vw;
  }
  section .layout-center .main-banner > div:first-child {
    padding-left: 0px;
    padding-right: 20px;
  }

  section .layout-center .hp-content-wrap > div:first-child {
    font-size: 5vw;
  }
  section .layout-center .hp-content-wrap > div .phone-images > div img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  section .layout-center .main-banner .download img {
    max-width: 35vw !important;
  }
  p {
    font-size: 16px;
    line-height: 145%;
  }
  section .layout-center .main-banner > div {
    padding: 5vw 0px;
  }

  section {
    padding: 5vw 0px;
  }
}

@media only screen and (max-width: 700px) {
  h1 {
    font-size: 5.4vw;
  }
  section .layout-center .main-banner > div:last-child {
    width: 30%;
  }
  header .middle-box .table-container .col.logo {
    padding: 15px 0px;
  }
  header .middle-box .table-container .col.logo img {
    width: 160px;
  }
  #sb-site {
    margin-top: -75px;
    padding-top: 75px;
  }
}

@media only screen and (max-width: 550px) {
  section.main-banner-wrap::after {
    width: 100%;
  }

  section .layout-center .main-banner {
    display: block;
  }
  section .layout-center .main-banner > div {
    display: block;
    width: 100% !important;
    text-align: center;
  }
  section .layout-center .main-banner > div:last-child {
    padding-top: 0px;
  }
  h1 {
    text-align: center;
    font-size: 8.5vw;
  }
  h1 span {
    border-bottom: 5px solid #fa7908;
  }
  section .layout-center .main-banner .download img {
    height: 61px !important;
    width: auto !important;
    max-width: none !important;
  }

  section .layout-center .main-banner::after {
    display: none;
  }
  section .layout-center .main-banner::before {
    bottom: -1px;
    left: 0px;
    top: auto;
    margin-left: 10%;
  }
  section .layout-center .main-banner > div:last-child img {
    height: 520px;
  }

  section .layout-center .hp-content-wrap {
    display: block;
  }
  section .layout-center .hp-content-wrap > div {
    display: block;
    width: 100% !important;
    text-align: center;
  }
  section .layout-center .hp-content-wrap > div:first-child {
    border-right: 0px;
    border-bottom: 5vw solid #ffffff;
    font-size: 10vw;
  }

  footer .copyright {
    text-align: center;
  }

  footer .box {
    height: 120px;
  }
  #sb-site {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 500px) {
  footer .box > div p {
    font-size: 4.8vw;
  }
  footer .box > div p.copyright {
    font-size: 3.8vw;
  }
}

@media only screen and (max-width: 1000px) {
  .desktop-visible {
    display: none;
  }

  .mobile-visible {
    display: block;
  }

  #layout #content .layout-content #main .patient-hospital .myTable {
    font-size: 8px;
  }

  #layout #content .layout-content #main .penfast-hospital .myTable {
    font-size: 8px;
  }

  #layout #content .layout-content #main .patient-profile .myTable {
    font-size: 8px;
  }

  .table-icon {
    font-size: 14px;
  }

  .content-history-label label {
    font-size: 8px;
  }
}

@media only screen and (min-width: 1000px) {
  .desktop-visible {
    display: block;
  }

  .mobile-visible {
    display: none;
  }
}
